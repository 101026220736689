<template>
<v-app-bar
       app
       absolute
       color="white"
       height="70"
     >
       <v-avatar
         class="mr-3"
         color="white lighten-5"
         size="70"
       >
         <v-img
           contain
           max-height="70%"
           src="@/assets/SPDP_Logo design_sketch-22.png"
         ></v-img>
       </v-avatar>

       <!-- <v-toolbar-title class="headline">
         Sunil Pathak Digital Production
       </v-toolbar-title> -->
       <template><v-spacer /></template>
       <!-- <template v-slot:extension> -->
        <v-tabs align-with-title>
          <v-tab><v-btn icon @click="$router.push('/')">home</v-btn></v-tab>
          <v-tab><v-btn icon @click="$router.push('/stories')">stories</v-btn></v-tab>
          <v-tab><v-btn icon @click="$router.push('/about')">about me</v-btn></v-tab>
        </v-tabs>
      <!-- </template> -->
</v-app-bar>
</template>

<script>
  // Utilities
  import {
    mapGetters,
    mapMutations,
  } from 'vuex'

  export default {
    name: 'CoreAppBar',

    computed: {
      ...mapGetters(['links']),
    },

    methods: {
      ...mapMutations(['toggleDrawer']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },
    },
  }
</script>

<style scoped>
  .headline {
     font-family: "Jim Nightshade", Helvetica, Arial !important;
     color: #022d36 !important;
  }
</style>